import './App.css';
import { useEffect, useState, useRef } from 'react';

const HOST = '';
const ALL_ITEMS_ENDPOINT = '/productssystemapi/api/system/v1/products/engagementgroup/complete'
const ITEM_ENDPOINT = '/productsprocessapi/api/process/v1/products/engagementgroup/complete'

// Create our number formatter.
const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

function App() {
  const [items, setItems] = useState([]);
  const [itemDetails, setItemDetails] = useState(null);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(300000);
  const [minCarat, setMinCarat] = useState(0);
  const [maxCarat, setMaxCarat] = useState(3);
  const [sortKey, setSortKey] = useState('price');
  const [sortDirection, setSortDirection] = useState('▲');
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingDetails, setIsFetchingDetails] = useState(false);
  const [topContentHeight, setTopContentHeight] = useState(0);
  const topContentRef = useRef(null);

  const fetchItems = () => {
    setIsFetching(true);
    // POST request using fetch inside useEffect React hook
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-IBM-Client-Secret': '73805214423d4AaebC96aD5581dbcf0b',
        'X-IBM-Client-Id': 'b9a8bfef128b495f8f17fb3cdeba5555',
        'Ocp-Apim-Subscription-Key': '',
      },
      body: JSON.stringify({
        "SiteId": 1,
        "assortmentId": 101,
        "PriceMarketId": 1,
        "Sku": "GRP10862"
      })
    };

    fetch(`${HOST}${ALL_ITEMS_ENDPOINT}`, requestOptions)
      .then(response => response.json())
      .then(data => {
        setItems(data?.resultDto?.group?.wpcEngagementGroupItems);
        setIsFetching(false);
        setTopContentHeight(topContentRef.current.clientHeight)
      }, (error) => {
        alert(`There was an error fetching data: ${error?.message}`);
        setIsFetching(false);
      });
  }

  const fetchItemDetails = (item) => {
    setIsFetchingDetails(true);
    // POST request using fetch inside useEffect React hook
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-IBM-Client-Secret': '73805214423d4AaebC96aD5581dbcf0b',
        'X-IBM-Client-Id': 'b9a8bfef128b495f8f17fb3cdeba5555',
        'Ocp-Apim-Subscription-Key': ''
      },
      body: JSON.stringify({
        "SiteId": 1,
        "assortmentId": 101,
        "selectedSku": item?.sku,
        "PriceMarketId": 1,
        "Sku": "GRP10862"
      })
    };

    fetch(`${HOST}${ITEM_ENDPOINT}`, requestOptions)
      .then(response => response.json())
      .then(data => {
        const details = { ...data?.resultDto?.group?.group };

        setItemDetails({
          ...details,
          price: item?.price,
          caratWeight: item?.caratWeight,
          totalQty: item?.totalQty,
          isAvailableOnline: item?.isAvailableOnline,
          hasInventory: item?.hasInventory,
          sku: item?.sku,
          color: details?.diamondColor ? details?.diamondColor[0] : details?.mipsDescription?.split(' ')?.length > 4 ? details?.mipsDescription?.split(' ')[4] : '?',
          clarity: details?.diamondClarity ? details?.diamondClarity[0] : details?.mipsDescription?.split(' ')?.length > 5 ? details?.mipsDescription?.split(' ')[5] : '?',
          cut: details?.diamondCut ? details?.diamondCut[0] : 'Excellent',
          provenance: details?.diamondProvenance ? details?.diamondProvenance[0] : '?',
          shape: details?.diamondShape ? details?.diamondShape[0] : '?'
        });
        setIsFetchingDetails(false);
        setTopContentHeight(topContentRef.current.clientHeight)
      }, (error) => {
        alert(`There was an error fetching data: ${error?.message}`);
        setIsFetchingDetails(false);
      });
  }

  useEffect(() => {
    setTopContentHeight(topContentRef.current.clientHeight)

    if (items?.length === 0) {
      fetchItems();
    }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  useEffect(() => {
    setTopContentHeight(topContentRef.current.clientHeight)
  }, [window?.innerHeight]);

  const filteredItems = items
    ?.slice()
    ?.filter(item => item?.price >= minPrice && item?.price <= maxPrice && item?.caratWeight >= (parseFloat(minCarat) || 0) && item?.caratWeight <= (parseFloat(maxCarat) || 0));

  return (
    <div className="App">
      <div id="topContent" ref={topContentRef}>
        <h4 style={{ marginBottom: 0 }}>Inventory List</h4>
        <p style={{ color: 'gray', fontSize: 10, marginBottom: 24 }}>The Tiffany® Setting only</p>
        <button onClick={fetchItems} disabled={isFetching}>Refresh</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button
          disabled={isFetching}
          onClick={() => {
            setMinPrice(0);
            setMaxPrice(300000);
            setMinCarat(0);
            setMaxCarat(3);
            setSortKey('price');
            setSortDirection('▲')
          }}
        >
          Reset Filters
        </button>
        <br /><br />
        <div style={{ display: 'inline-flex', width: 360, justifyContent: 'space-between' }}>
          <div>
            <form>
              <div>
                <label htmlFor="min-price">Min Price&nbsp;</label>
                <input type="number" id="min-price" name="min-price" min={0} max={300000} value={minPrice?.toString()} onChange={(e) => setMinPrice(parseFloat(e?.target?.value))} />
              </div>
              <div style={{ marginTop: 8 }}>
                <label htmlFor="max-price">Max Price&nbsp;</label>
                <input type="number" id="max-price" name="max-price" min={0} max={300000} value={maxPrice?.toString()} onChange={(e) => setMaxPrice(parseFloat(e?.target?.value))} />
              </div>
            </form>
          </div>

          <div>
            <form>
              <div>
                <label htmlFor="min-carat">Min Carat&nbsp;</label>
                <input type="text" id="min-carat" name="min-carat" size={6} value={minCarat?.toString()} onChange={(e) => setMinCarat(e?.target?.value?.replace(/[^0-9.]/g, ''))} />
              </div>
              <div style={{ marginTop: 8 }}>
                <label htmlFor="max-carat">Max Carat&nbsp;</label>
                <input type="text" id="max-carat" name="max-carat" size={6} value={maxCarat?.toString()} onChange={(e) => setMaxCarat(e?.target?.value?.replace(/[^0-9.]/g, ''))} />
              </div>
            </form>
          </div>
        </div>

        <br /><br />

        {itemDetails &&
          <div style={{ border: `1px solid #0abab5`, width: 360, marginLeft: 'auto', marginRight: 'auto', marginBottom: 24 }}>
            <div style={{ marginBottom: -16 }}>
              <h4>{currencyFormatter.format(itemDetails?.price)}</h4>
            </div>

            <div style={{ display: 'inline-flex' }}>
              <div style={{ width: 60, textAlign: 'center' }}>
                <h4>{itemDetails?.caratWeight}</h4>
                <h6 style={{ color: 'gray', marginTop: -8 }}>Carat</h6>
              </div>
              <div style={{ width: 60, textAlign: 'center' }}>
                <h4>{itemDetails?.color}</h4>
                <h6 style={{ color: 'gray', marginTop: -8 }}>Color</h6>
              </div>
              <div style={{ width: 60, textAlign: 'center' }}>
                <h4>{itemDetails?.clarity}</h4>
                <h6 style={{ color: 'gray', marginTop: -8 }}>Clarity</h6>
              </div>
              <div style={{ width: 60, textAlign: 'center' }}>
                <h4>{itemDetails?.cut}</h4>
                <h6 style={{ color: 'gray', marginTop: -8 }}>Cut</h6>
              </div>
            </div>

            <div style={{ marginLeft: 16, marginTop: -16, marginBottom: -8, display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <h5>{itemDetails?.provenance}</h5>
                <h6 style={{ color: 'gray', marginTop: -16 }}>Provenance</h6>
              </div>
              <div style={{ marginTop: 10, marginRight: 12 }}>
                <p style={{ fontSize: '12px', color: 'gray' }}><b>Created</b> {new Date(itemDetails?.dateCreated).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" })}</p>
                &nbsp;&nbsp;
                <p style={{ fontSize: '12px', color: 'gray', marginTop: -24 }}><b>Updated</b> {new Date(itemDetails?.dateUpdated).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" })}</p>
              </div>
            </div>

            <div style={{ textAlign: 'right', marginRight: 16, marginBottom: 12, marginTop: 12 }}>
              <a
                href={`https://www.tiffany.com/engagement/engagement-rings/the-tiffany-setting-engagement-ring-in-platinum-GRP10862/the-tiffany-setting-engagement-ring-in-platinum-${itemDetails?.sku}/`}
                target={'_blank'}
                rel="noreferrer"
                style={{ textDecoration: 'none', color: '#0abab5' }}
                title={itemDetails?.sku}
              >
                💍 View Details
              </a>
            </div>
          </div>

        }

        {isFetching ? <div><h5 style={{ color: '#0abab5' }}>Fetching Results...</h5></div> : null}
        {isFetchingDetails ? <div><h5 style={{ color: '#0abab5' }}>Fetching Item Details...</h5></div> : null}

        {!isFetching && !isFetchingDetails && (
          <div style={{ color: 'gray' }}>
            <h5>{filteredItems?.length} Results</h5>
          </div>
        )}
      </div>

      <div style={{ display: 'inline-block', overflowY: 'scroll', minHeight: 300, maxHeight: window.innerHeight - topContentHeight - 50, border: '2px solid lightgray', width: '100%', borderLeft: 'none', borderRight: 'none' }}>
        <table>
          <thead>
            <tr>
              <th>SKU</th>
              <th style={{ cursor: 'pointer', color: sortKey === 'price' ? '#0abab5' : undefined }} onClick={() => { setSortKey('price'); setSortDirection(sortDirection === '▲' ? '▼' : '▲') }}>Price {sortKey === 'price' && sortDirection}</th>
              <th style={{ cursor: 'pointer', color: sortKey === 'caratWeight' ? '#0abab5' : undefined }} onClick={() => { setSortKey('caratWeight'); setSortDirection(sortDirection === '▲' ? '▼' : '▲') }}>Carat {sortKey === 'caratWeight' && sortDirection}</th>
              <th>Quantity</th>
              <th>Online</th>
              <th>Has Inventory</th>
            </tr>
          </thead>

          <tbody>
            {filteredItems
              ?.sort((a, b) => sortDirection === '▲' ? (parseFloat(a[sortKey]) || 0) - (parseFloat(b[sortKey]) || 0) : (parseFloat(b[sortKey]) || 0) - (parseFloat(a[sortKey]) || 0))
              ?.map(item => (
                <tr
                  key={item?.sku}
                  onClick={() => !isFetchingDetails ? fetchItemDetails(item) : null}
                  style={{
                    backgroundColor: item?.sku === itemDetails?.sku ? '#0abab5' : undefined,
                    color: item?.sku === itemDetails?.sku ? '#FFF' : undefined,
                    fontWeight: item?.sku === itemDetails?.sku ? 'bold' : undefined,
                    fontStyle: item?.sku === itemDetails?.sku ? 'italic' : undefined,
                  }}
                >
                  <td>
                    <a
                      href={`https://www.tiffany.com/engagement/engagement-rings/the-tiffany-setting-engagement-ring-in-platinum-GRP10862/the-tiffany-setting-engagement-ring-in-platinum-${item?.sku}/`}
                      target={'_blank'}
                      rel="noreferrer"
                      style={{ textDecoration: 'none' }}
                    >
                      {item?.sku}
                    </a>
                  </td>
                  <td>{currencyFormatter.format(item?.price)}
                  </td>
                  <td>{item?.caratWeight}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                  <td>{item?.totalQty}</td>
                  <td>{item?.isAvailableOnline ? `✅` : `❌`}</td>
                  <td>{item?.hasInventory ? `✅` : `❌`}</td>
                </tr>
              ))}
          </tbody>


        </table>
      </div>

    </div>
  );
}

export default App;